@font-face {
  font-family: "Avenir Next";

  src: local("Avenir Next"),
    url(./assets/fonts/AvenirNextLTPro-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Avenir Next";
  font-weight: bold;
  src: local("Avenir Next"),
    url(./assets/fonts/AvenirNextLTPro-Bold.otf) format("opentype");
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: white;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #c4c4c4;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}
